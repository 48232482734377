<template>
    <v-container>
        <div style="width=100%" class="mt-12 d-flex flex-column align-center text-center">
            <v-card color="red" width="600">
                <h1 class="mt-12 black--text">
                    {{ $t('locked.title') }}
                </h1>
                <p class="black--text"  v-html="$t('locked.description')">
                </p>
                <div class="mt-12 mb-12">
                    <v-btn text @click="logout_and_back()">
                        <span class="black--text">
                            {{ $t('locked.btn.logout_back') }}
                        </span>
                    </v-btn>
                </div>
            </v-card>
        </div>
    </v-container>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => ({

    }),
    methods: {
        logout_and_back() {
            this.$store.dispatch("auth/act_logout").then(() => {
                this.$router.push({ name: 'Admin' })
            })
        }
    },
    mounted() { },
    created() { },
    computed: {},
    watch: {}
}
</script>

<style>

</style>